const envElem = document.querySelector('.js-envelope')
const modalTriggerElem = document.querySelector('.js-openModal')
let viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
let envelopeHeight = 0
let storedScrollTop = 0

/**
 * Update the viewport width
 * @returns {void}
 */
function setViewportWidth() {

  viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
}

/**
 * Update the viewport height
 * @returns {void}
 */
function setViewportHeight() {

  viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
}

/**
 * Update the envelope height
 * @returns {void}
 */
function setEnvelopeHeight() {

  envelopeHeight = Math.max(envElem.getBoundingClientRect().height, 0)
}

/**
 * Set the vh property based on the calculated window innerHeight.  This is necessary because some mobile devices
 * calculate the vh size including the address bar while other do not.
 * @returns {void}
 */
function setCssVhProp() {

  let vh = window.innerHeight * 0.01
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

/**
 * Handle the "open" state of the envelope.
 * @param isScroll
 * @returns {void}
 */
function open(isScroll = false) {

  if (!envElem.classList.contains('.is-active')) {
    const newScrollTop = window.pageYOffset || document.documentElement.scrollTop

    if (viewportWidth < 1024 && isScroll) {
      if (newScrollTop > storedScrollTop) {
        window.removeEventListener('scroll', handleScroll)
        setTimeout(handleEnvelopeState, 750)
      }
    } else {
      window.removeEventListener('scroll', handleScroll)
      setTimeout(handleEnvelopeState, 5000)
    }
  }
}

/**
 * Due to the complexity of the animation and positioning of the envelope, we must programmatically update
 * the bottom offset of the envelope based on the screen size if larger than 1024px
 * @param isOpening
 * @returns {void}
 */
function updateEnvelopePosition(isOpening = false) {
  if (viewportWidth >= 1024) {
    // if the envelope is opening
    if (isOpening) {
      envElem.style.removeProperty('bottom')
    } else {
      if (envElem.classList.contains('close')) {
        // determine the space remaining on either side of the Y-axis of the envelope, based on viewport height
        const envelopeOffset = (viewportHeight - envelopeHeight) / 2
        // update the bottom offset of the envelope so that it is 50% of the screen
        envElem.style.setProperty('bottom', `${envelopeOffset}px`)
      }
    }
  } else {
    envElem.style.removeProperty('bottom')
  }
}

/**
 * Handle switching state of the envelope
 * @returns {void}
 */
function handleEnvelopeState() {

  envElem.classList.add('open')
  envElem.classList.remove('close')
  modalTriggerElem.classList.add('is-active')
  modalTriggerElem.ariaDisabled = 'false'
  modalTriggerElem.ariaHidden = 'false'
  updateEnvelopePosition(true)
}

/**
 * Handle logic for resize event
 * @returns {void}
 */
function handleResize() {

  setCssVhProp()
  setViewportWidth()
  setViewportHeight()
  setEnvelopeHeight()
  updateEnvelopePosition()
  // if the screen size is larger than 1024px, animate
  if (viewportWidth >= 1024) {
    open()
  }
}

/**
 *
 * @param event
 * @returns {void}
 */
function handleScroll(event) {
  open(true)
}

handleResize()
window.addEventListener('scroll', handleScroll)
window.addEventListener('resize', handleResize)
